<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <div id="print">

      <v-data-table :headers="headers" :items="company_list" :footer-props="{ 'items-per-page-options': [50, 100, -1] }"
        class="elevation-1" :search="search">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Company</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <!-- <v-spacer> -->
            <!-- one -->
            <v-btn color="blue" style="float:right" dark @click="exceldialog1 = !exceldialog1">Excel Import</v-btn>
            <v-dialog width="350" persistent v-model="exceldialog1">
              <v-card>
                <v-card-title class="c-title">Excel import</v-card-title>
                <v-card-text><br />
                  <v-row justify="end">
                    <a :href="url">
                      <v-icon large color="blue" class="icon-quick" title="Download">
                        mdi-file-excel
                      </v-icon>
                    </a>
                  </v-row>
                  <v-file-input v-model="excelfile1" label="Import Excel"></v-file-input>
                </v-card-text>
                <v-card-actions>
                  <v-row class="pr-5" justify="end">
                    <v-btn @click="clear1()" text>cancel</v-btn>
                    <v-btn @click="importexcel()" dark color="blue">Upload</v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- two -->
            <v-dialog persistent width="500" v-model="excelerrordialog1">
              <v-card>
                <v-card-title class="c-title">
                  <v-row justify="center">
                    <b>Excel Error</b>

                    <download-excel class="mar" :data="notfoundcompany" :fields="table_fields_excel"
                      worksheet="AddCompany_error" name="AddCompany_error.xls">
                      <v-btn color="success" dark class="mb-2">
                        <v-icon dark>mdi-file-excel</v-icon>
                      </v-btn>
                    </download-excel>

                  </v-row>
                </v-card-title>

                <v-card-text><br /><br />

                  <table style="width:100%" v-if="Array.isArray(notfoundcompany) &&
                    notfoundcompany.length
                    " class="pr-5" justify="center">
                    <thead>
                      <th style="font-size:35px; ">Company</th>
                      <th style="font-size:35px;">Error</th>
                    </thead>
                    <tbody>
                      <br>
                      <tr class="ma-5" v-for="(item, index) in notfoundcompany" :key=index>
                        <td class="text-center text-subtitle-2"> {{ item.Company_name }} </td>
                        <td class="text-center text-subtitle-2 red--text"> {{ item.error }} </td>
                      </tr>
                    </tbody>
                  </table>


                </v-card-text>
                <v-card-actions>
                  <v-row class="pr-5" justify="end">
                    <v-btn color="white" dark class="mb-2" @click="closeerrordialog1()" style="
                                background-color: #1867c0 !important;
                                color: white !important;
                              ">Close</v-btn>
                    <!-- <v-btn @click="closeerrordialog1()" text>Close</v-btn> -->
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- three -->

            <v-spacer> </v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
            </v-text-field>
            <span style="width: 20px"></span>

            <v-dialog v-model="dialog" max-width="500px">
             
              <v-card>
                <v-form v-model="valid">
                  <v-card-title>
                    <v-row justify="space-between">
                      <span class="headline"> New Company</span>
                      <v-btn icon dark @click="dialog = false">
                        <v-icon color="black">mdi-close</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <label> Company<span class="required" :rules="[(v) => !!v || 'required']">*</span>
                          </label>
                          <v-text-field v-model="name" :rules="[(v) => !!v || 'required']">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <label> Company in Short Name<span class="required"
                              :rules="[(v) => !!v || 'required']">*</span></label>
                          <v-text-field v-model="short_name" :rules="[(v) => !!v || 'required', size_rules]" counter="10">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <label> Company Abbreviation<span class="required"
                              :rules="[(v) => !!v || 'required']">*</span></label>
                          <v-text-field v-model="abbr" :rules="[(v) => !!v || 'required', size_rules]" counter="10">
                          </v-text-field>
                        </v-col>


                        <v-col cols="12" sm="12" md="12">
                          <label> company Website</label>
                          <v-text-field v-model="website_url">
                          </v-text-field>
                        </v-col>

                        <label>Company logo :</label>

                        <v-file-input type="file" placeholder="Select Logo" prepend-icon="mdi-file" ref="file" show-size
                          required="true" clearable v-on="on" v-model="attach">
                        </v-file-input>
                        <font style="color: red; font-size:11px; ">&emsp;&emsp;File Size Should be less than 250 kb,JPEG,
                          PNG, JPG & PDF </font>





                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="primary darken-1" @click="save">Save</v-btn>
                    <!-- :disabled="!valid" -->
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog2" max-width="500px">

              <v-card>
                <v-form v-model="valid">
                  <v-card-title>
                    <v-row justify="space-between">
                      <span class="headline"> Edit Company</span>
                      <v-btn icon dark @click="dialog2 = false">
                        <v-icon color="black">mdi-close</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <label> Company<span class="required" :rules="[(v) => !!v || 'required']">*</span>
                          </label>
                          <v-text-field v-model="name" :rules="[(v) => !!v || 'required']">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <label> Company in Short Name<span class="required"
                              :rules="[(v) => !!v || 'required']">*</span></label>
                          <v-text-field v-model="short_name" :rules="[(v) => !!v || 'required', size_rules]" counter="10">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <label> Company Abbreviation<span class="required"
                              :rules="[(v) => !!v || 'required']">*</span></label>
                          <v-text-field v-model="abbr" :rules="[(v) => !!v || 'required', size_rules]" counter="10">
                          </v-text-field>
                        </v-col>


                        <v-col cols="12" sm="12" md="12">
                          <label> company Website</label>
                          <v-text-field v-model="website_url">
                          </v-text-field>
                        </v-col>

                        <label>company logo :</label>

                        <v-file-input type="file" placeholder="Select Logo" prepend-icon="mdi-file" ref="file" show-size
                          required="true" clearable v-on="on" v-model="attach">
                        </v-file-input>


                        <font style="color: red; font-size:11px; ">&emsp;&emsp;File Size Should be less than 250 kb,JPEG,
                          PNG, JPG & PDF </font>




                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close1">Cancel</v-btn>
                    <v-btn color="primary darken-1" @click="edit">Update</v-btn>
                    <!-- :disabled="!valid" -->
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-btn class="primary logi" @click="addCompany_dialog()" >
              <v-icon>mdi mdi-plus</v-icon>Add Company
              </v-btn>
            <span class="mar"><v-btn color="error" dark class="mb-2" @click="generateCartPdf()"><v-icon
                  dark>mdi-file-pdf-box</v-icon></v-btn></span>
            <download-excel class="mar" :data="company_list" :fields="table_fields" worksheet="Company_List"
              name="Company_List.xls">
              <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <div>

            <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>




            <v-icon color="red" @click="deletepresentCompany(item.id)">
              mdi-delete</v-icon>
          </div>
        </template>
        <template v-slot:item.isactive="{ item }">
          <v-switch v-model="item.isactive" @click="activepresentCompany(item)"></v-switch>
        </template>

        <template v-slot:item.attach="{ item }">
          <span v-if="item.attach != '-'">
            <v-icon class="green--text" v-model="item.attach" @click="getlogo(item)">mdi mdi-eye</v-icon>
          </span>
          <span v-else>
            --
          </span>
        </template>

        <template v-slot:item.website_url="{ item }">
          <span v-if="item.website_url != '-' && item.website_url">
            <v-icon class="green--text" @click="geturl(item)">mdi mdi-eye</v-icon>
          </span>
          <span v-else>
            --
          </span>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog_attach" max-width="500px">
        <v-card>
          <v-img :src="url">
          </v-img>
        </v-card>
      </v-dialog>


    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
import readXlsxFile from "read-excel-file";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Template from '../AdminMaster/Template.vue';
export default {
  components: { Template },
  data: () => ({


    dialog_attach: false,

    table_fields_excel: {
      "Sr. No.": "srno",
      "Company": "Company_name",
      "Error": "error",
    },
    table_fields: {
      "Sr. No.": "srno",
      "Company Name": "name",
      "URL": "url",
      "Logo_url": "attach",
      "Company Short Name": "short_name",
      "Company Abbreviation": "abbr",
      Active: "isactive",
    },
    size_rules: [(v) => v.length <= 25 || "Max 25 characters"],

    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    overlay: false,
    valid: false,

    errorMsg: {
      id: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    dialog2: false,
    dialog_attach: false,
    company_list: null,

    headers: [
      {
        text: "Sr. No",
        align: "left",
        sortable: false,
        value: "srno",
      },
      {
        text: "Company",
        align: "left",
        sortable: false,
        value: "name",
      },
      {
        text: "URL",
        align: "left",
        sortable: false,
        value: "website_url",
      },
      {
        text: " Company logo",
        align: "left",
        sortable: false,
        value: "attach",
      },
      {
        text: "Short Name",
        align: "left",
        sortable: false,
        value: "short_name",
      },
      {
        text: "Abbreviation",
        align: "left",
        sortable: false,
        value: "abbr",
      },

      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    addedlogo: "",
    company_list: [],
    website_url: "",
    logo_filename: "",
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      isactive: true,
      short_name: "",
      abbr: "",
      url: "",
      attach: null,
      website_url: "",
      company_name: '',

    },
    excelerrordialog1: false,
    notfoundcompany: [],
    excelfile1: null,
    exceldialog1: false,

    name: "",
    short_name: "",
    abbr: "",
    isactive: true,
    website_url: "",
    attach: null,
    id: "",
    url: "",

  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Company" : "Edit Company";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },

  // created () {
  //   this.initialize()
  // },
  mounted() {
    this.onLoad();
  },
  methods: {
    addCompany_dialog()
    {
      this.name = "";
      this.short_name = "";
      this.abbr = "";
      this.website_url ="";
      this.attach = null;
      this.id = "";
      this.dialog=true;
    },
    hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },

    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
    },
    onLoad() {
      this.overlay = true;
      // this.onLoad();
      axios
        .post("/TPOCompanyScheduling/addCompany")
        .then((res) => {
          if (res.data.msg == "200") {
            if ((this.load = true)) {
              this.overlay = false;
              // this.onLoad();
              this.company_list = res.data.company_list.sort();
              this.url = res.data.url;
              // this.addedlogo = res.data.logo_filename;
              this.attach = res.data.attach
            }
          } else {
            //window.console.log(res.data.msg);
            this.overlay = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
          this.overlay = false;
        })
        .finally(() => {
          // 'use strict';
          this.overlay = false;
        });
    },


    importexcel() {
      // alert("importexcel");
      var company_list = [];

      readXlsxFile(this.excelfile1).then((rows) => {
        //console.log(rows);
        var i;
        for (i = 1; i < rows.length; i++) {
          var temp = {};
          temp['Company_name'] = rows[i][0];
          temp['Short_name'] = rows[i][1];
          temp['Abbreviation'] = rows[i][2];
          temp['Url'] = rows[i][3];
          temp['Attach'] = rows[i][4];
          company_list.push(temp);
        }

        var params = { "company_list": company_list };
        axios.post("/TPOCompanyScheduling/companyimport", params).then((res) => {
          if (res.data.msg == "200") {
            // this.showSnackbar("green", "Company added successfully..");
            // this.fetchReport();
            //console.log(res.data.errorlist.length)
            if (res.data.errorlist.length != 0) {
              this.excelerrordialog1 = true;
              this.notfoundcompany = res.data.errorlist;
            }
            else {
              // this.showSnackbar("green", "Success..");
            }
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        });

      });

      this.exceldialog1 = false;
    },

    clear1() {
      this.excelfile1 = null;
      this.exceldialog1 = false;

    },
    closeerrordialog1() {
      this.clear1();
      this.excelerrordialog1 = false;
    },


    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem(item) {
      this.dialog2 = true;
     
      this.name = "";
      this.short_name = "";
      this.abbr = "";
      this.website_url ="";
      this.attach = null;
      this.id = "";

      this.name = item.name;
      this.short_name = item.short_name;
      this.abbr = item.abbr;
      this.website_url = item.website_url;
      this.attach = null;
      this.id = item.id;

    },
    close() {
      this.dialog = false;
    },
    close1() {
      this.dialog2 = false;
    },
    edit() {



      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      if (this.name == "" || this.name == null || /^[ \d]+$/.test(this.name) || this.hasWhiteSpace(this.name)) {
        this.showSnackbar("#b71c1c", "Please Enter Company Name ...");
        return;
      }
      else if (this.short_name == "" || this.short_name == null || /^[ \d]+$/.test(this.short_name) || this.hasWhiteSpace(this.short_name)) {
        this.showSnackbar("#b71c1c", "Please Enter Company in Short Name ...");
        return;
      } else if (this.name == this.short_name) {

        this.showSnackbar("#b71c1c", "Company Name And Company in Short Name Should Not Same ...");
        return;
      }
      else if (this.abbr == "" || this.abbr == null || /^[ \d]+$/.test(this.abbr) || this.hasWhiteSpace(this.abbr)) {
        this.showSnackbar("#b71c1c", "Please Enter Company Abbreviation ...");
        return;
      } else if (this.website_url && !regex.test(this.website_url) || /^[ \d]+$/.test(this.website_url) || this.hasWhiteSpace(this.website_url)) {
        this.showSnackbar("#b71c1c", "Please Enter Valid URL Of Company Website ...");
        return;
      }
      else if (this.attach) {
        //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
        var file = this.attach.name;
        var ext = file.split(".");
        ext = ext[ext.length - 1].toLowerCase();
        var arrayExtensions = ["pdf", "jpeg", "png", "jpg"];
        if (arrayExtensions.lastIndexOf(ext) == -1) {
          this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & PNG File...");
          return
        }
        //for only allow upload cv when size is 250kb
        const upload = this.attach;
        const maxSize = 250 * 1024;

        if (upload > maxSize) {
          this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
          return
        }

      }
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("id", this.id);
      formData.append("short_name", this.short_name);
      formData.append("abbr", this.abbr);
      formData.append("isactive", this.isactive);
      formData.append("website_url", this.website_url);

      if (this.attach) {
        formData.append("attach", this.attach);
      }
      axios
        .post("/TPOCompanyScheduling/editcompany_new", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {


          if (res.data.msg == "200") {
            this.overlay = false;
            this.dialog2 = false;
            this.dialog_attach = false;
            this.showSnackbar("#4caf50", "Company Update Successfully..."); // show snackbar on success
            this.onLoad();

          } else {
            this.showSnackbar("red", res.data.msg); // show snackbar on success
          }
        })
        .catch((error) => {
          window.console.log(error);

        });

      this.close();



    },
    save() {


   
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      if (this.name == "" || this.name == null || /^[ \d]+$/.test(this.name) || this.hasWhiteSpace(this.name)) {
        this.showSnackbar("#b71c1c", "Please Enter Company Name ...");
        return;
      }
      else if (this.short_name == "" || this.short_name == null || /^[ \d]+$/.test(this.short_name) || this.hasWhiteSpace(this.short_name)) {
        this.showSnackbar("#b71c1c", "Please Enter Company in Short Name ...");
        return;
      } else if (this.name == this.short_name) {

        this.showSnackbar("#b71c1c", "Company Name And Company in Short Name Should Not Same ...");
        return;
      }
      else if (this.abbr == "" || this.abbr == null || /^[ \d]+$/.test(this.abbr) || this.hasWhiteSpace(this.abbr)) {
        this.showSnackbar("#b71c1c", "Please Enter Company Abbreviation ...");
        return;
      } else if (this.website_url && !regex.test(this.website_url) || /^[ \d]+$/.test(this.website_url) || this.hasWhiteSpace(this.website_url)) {
        this.showSnackbar("#b71c1c", "Please Enter Valid URL Of Company Website ...");
        return;
      }
      else if (this.attach) {
        //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
        var file = this.attach.name;
        var ext = file.split(".");
        ext = ext[ext.length - 1].toLowerCase();
        var arrayExtensions = ["pdf", "jpeg", "png", "jpg"];
        if (arrayExtensions.lastIndexOf(ext) == -1) {
          this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & PNG File...");
          return
        }
        //for only allow upload cv when size is 250kb
        const upload = this.attach;
        const maxSize = 250 * 1024;

        if (upload > maxSize) {
          this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
          return
        }

      }
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("short_name", this.short_name);
      formData.append("abbr", this.abbr);
      formData.append("isactive", this.isactive);
      formData.append("website_url", this.website_url);

      if (this.attach) {
        formData.append("attach", this.attach);
      }
      axios
        .post("/TPOCompanyScheduling/savecompany_new", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {


          if (res.data.msg == "200") {
            this.overlay = false;
            this.dialog_attach = false;
            this.showSnackbar("#4caf50", "Company Added Successfully..."); // show snackbar on success
            this.onLoad();

          } else {
            this.showSnackbar("red", res.data.msg); // show snackbar on success
          }
        })
        .catch((error) => {
          window.console.log(error);

        });

      this.close();



    },

    deletepresentCompany(item) {
      //console.log("a");

      const data = {
        deleteitem: item,
      };
      if (confirm("Do you really want to delete?")) {
        axios
          .post("/TPOCompanyScheduling/deleteCompany", data)
          .then((res) => {
            if (res.data.msg == "200") {
              //console.log("success");
              this.showSnackbar("#4caf50", "Deleted Successfully...");
              this.onLoad();
            } else {
              //console.log("fail");
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, // end of delete // end of save()

    activepresentCompany(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      //console.log(item.id);

      axios
        .post("/TPOCompanyScheduling/activeCompany", data)
        .then((res) => {
          //console.log("res.data");
          //console.log(res.data);
          if (res.data.msg == "200") {
            //console.log("res.data");
            //console.log(res.data);
            //console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            //console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    }, // end of delete

    getlogo(item) {
      //   alert("aleart.......")

      // console.log("doneeeee");

      const data = {
        item: item.id,
      };
      axios
        .post("/TPOCompanyScheduling/getlogo", data)
        .then((res) => {
          if (res.data.msg == "200") {

            this.url = res.data.attach;

            //console.log(this.url);

            this.dialog_attach = true;
          }

          else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "something went wrong");
          //window.console.log(error);
        });
      this.close();
    },

    geturl(item) {
      //console.log("get url");
      //console.log(item);
      //  let route = this.$router.resolve({ path: "/website_url" });
      //       window.open(route.href);
      var website = item.website_url;
      // let route = this.$router.resolve(website);
      //        window.open(route.href);
      //alert(website);
      window.open(website, "_blank");


    },




    generateCartPdf() {
      const doc = new jsPDF();
      doc.autoTable({
        theme: "grid",
        body: this.company_list,
        columns: [
          { header: "Sr. no", dataKey: "srno" },
          { header: "Company Name", dataKey: "name" },
          { header: "Active", dataKey: "isactive" },
        ],
      });
      doc.save("Company_List.pdf");
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.required {
  color: red;
  font-size: 14px;
  font-style: bold;
}

.mar {
  padding: 5px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
